import * as React from 'react';
import { SubmissionList, SubmissionShow } from './pages/Submissions';
import { Admin, Resource, MenuItemLink } from 'react-admin';
import { Title } from 'react-admin';
import { Card, CardContent, Box } from '@material-ui/core';

import {
	FirebaseDataProvider,
	FirebaseAuthProvider,
} from 'react-admin-firebase';

import { firebaseConfig as config } from './FIREBASE_CONFIG';

const options = {
	logging: true,
};
const dataProvider = FirebaseDataProvider(config, options);
const authProvider = FirebaseAuthProvider(config, options);

const Stats = () => {
	return (
		<Box marginTop={3}>
			<Card>
				<Title title="EU TAF Administration" />
				<CardContent>Statistics and charts</CardContent>
			</Card>
		</Box>
	);
};

class App extends React.Component {
	render() {
		return (
			<Admin
				title="EU TAF"
				dataProvider={dataProvider}
				authProvider={authProvider}
				dashboard={Stats}>
				<Resource
					name="submissions"
					list={SubmissionList}
					show={SubmissionShow}
				/>
			</Admin>
		);
	}
}

export default App;
