import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

export const PdfDocument = ({ data }) => {
	return (
		<Document>
			<Page style={styles.body}>
				<Text style={styles.header} fixed>
					EU-TAF Programme
				</Text>
				<Text style={{ ...styles.header, marginBottom: 20 }} fixed>
					Supporting the Lebanese Productive Private Sector
				</Text>
				<View style={{ flex: 1, paddingBottom: 20 }}>
					<Text
						style={{
							fontSize: 24,
							fontWeight: 'bold',
							color: '#00cc00',
							textAlign: 'center',
						}}>
						Programme Participation Engagement Form*
					</Text>
					<Text
						style={{
							fontSize: 10,
							color: '#00cc00',
							textAlign: 'center',
						}}>
						*all information will be treated conidentially and
						unseen by any person out side of core TAT team (3
						people)
					</Text>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text style={styles.tableTitle}>
							General Company Information
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Company Name</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.companyName}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Industry Sector</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.industrySector}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							Headquarter Address
						</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.headquarterAddress}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Company Reg. No.</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.companyRegNumber}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>CEO</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.ceo}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Share Capital</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.shareCapital}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							Contacts Name for Official correspondence
						</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.contactName}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Title</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.title}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Phone</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.phone}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Email</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.email}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Website</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.website}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Social Media</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.socialMedia}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={{ ...styles.cell, borderBottom: 0 }}>
						<Text style={styles.cellLabel}>Industry/Segment</Text>
					</View>
					<View style={{ ...styles.cell, borderBottom: 0 }}>
						<Text>{data.industrySegment}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Date Established</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.dateEstablished}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={{ ...styles.cell, borderTop: 0 }}>
						<Text style={styles.cellLabel}></Text>
					</View>
					<View style={{ ...styles.cell, borderTop: 0 }}>
						<Text style={styles.cellLabel}></Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							Number of Employees
						</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.numberOfEmployees}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							Customer Focus / e.g. B2B, B2C
						</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.customerFocus}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Customer type</Text>
						<Text style={styles.cellSubLabel}>
							e.g. Retail, Wholesale, Distributer End Consumer
						</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.customerType}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							Operation Type (e.g. manufacturing, service,
							application developer,)
						</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.operationType}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							Main Products/Services
						</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.mainProducts}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Business Type</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.businessType}</Text>
					</View>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>Legal Company Form</Text>
					</View>
					<View style={styles.cell}>
						<Text>{data.legalForm}</Text>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							Ownership of Shares
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={{ ...styles.cell, textAlign: 'center' }}>
						<Text style={{ fontWeight: 'bold' }}>
							Individual/Family
						</Text>
						<Text style={styles.cellSubLabel}>
							(Owned by Management)
						</Text>
					</View>
					<View style={{ ...styles.cell, textAlign: 'center' }}>
						<Text style={{ fontWeight: 'bold' }}>
							Institutional
						</Text>
						<Text style={styles.cellSubLabel}>
							(Investors.VC etc)
						</Text>
					</View>
					<View style={{ ...styles.cell, textAlign: 'center' }}>
						<Text style={styles.cellLabel}>Partners</Text>
						<Text style={styles.cellSubLabel}>
							(e.g. distributers, Suppliers, Customers)
						</Text>
					</View>
					<View style={{ ...styles.cell, textAlign: 'center' }}>
						<Text style={{ fontWeight: 'bold' }}>Employees</Text>
						<Text style={styles.cellSubLabel}>(ESOP)</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={{ ...styles.cell, textAlign: 'center' }}>
						<Text style={styles.cellLabel}>
							{data.sharesManagement
								? data.sharesManagement.indexOf('%') > -1
									? data.sharesManagement
									: data.sharesManagement + '%'
								: '0%'}
						</Text>
					</View>
					<View style={{ ...styles.cell, textAlign: 'center' }}>
						<Text style={styles.cellLabel}>
							{data.sharesInvestors
								? data.sharesInvestors.indexOf('%') > -1
									? data.sharesInvestors
									: data.sharesInvestors + '%'
								: '0%'}
						</Text>
					</View>
					<View style={{ ...styles.cell, textAlign: 'center' }}>
						<Text style={styles.cellLabel}>
							{data.sharesPartners
								? data.sharesPartners.indexOf('%') > -1
									? data.sharesPartners
									: data.sharesPartners + '%'
								: '0%'}
						</Text>
					</View>
					<View style={{ ...styles.cell, textAlign: 'center' }}>
						<Text style={styles.cellLabel}>
							{data.esop
								? data.esop.indexOf('%') > -1
									? data.esop
									: data.esop + '%'
								: '0%'}
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View
						style={{
							...styles.cell,
							borderBottom: 0,
							flex: 1,
							maxWidth: '25%',
						}}>
						<Text style={{ fontWeight: 'bold' }}>
							Internal Value Add
						</Text>
						<Text style={styles.cellSubLabel}>
							(what you add to raw materials, and other externally
							sourced services & goods)
						</Text>
					</View>
					<View
						style={{
							...styles.cell,
							borderBottom: 0,
							flex: 3,
							maxWidth: '75%',
						}}>
						<Text style={{ textAlign: 'right' }}>
							{data.inHouseAddedValue}
							{data.inHouseAddedValue.indexOf('%') > -1
								? ''
								: '%'}
						</Text>
						<Text style={styles.cellSubLabel}>
							In-house Added Value (The % of our Revenue which is
							difference between cost of what you buy & price of
							finished product
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View
						style={{
							...styles.cell,
							borderTop: 0,
							borderBottom: 0,
							flex: 1,
							maxWidth: '25%',
						}}>
						<Text style={{ fontWeight: 'bold' }}>
							Local Economy Value Add
						</Text>
						<Text style={styles.cellSubLabel}>
							(locally sourced raw materials, services, logistics
							etc
						</Text>
					</View>
					<View
						style={{
							...styles.cell,
							borderTop: 0,
							borderBottom: 0,
							flex: 3,
							maxWidth: '75%',
						}}>
						<Text style={{ textAlign: 'right' }}>
							{data.localRawMaterials}
							{data.localRawMaterials.indexOf('%') > -1
								? ''
								: '%'}
						</Text>
						<Text style={styles.cellSubLabel}>
							which is Local Raw Materials
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View
						style={{
							...styles.cell,
							borderTop: 0,
							borderBottom: 0,
							flex: 1,
							maxWidth: '25%',
						}}></View>
					<View
						style={{
							...styles.cell,
							borderTop: 0,
							borderBottom: 0,
							flex: 3,
							maxWidth: '75%',
						}}>
						<Text style={{ textAlign: 'right' }}>
							{data.localServices}
							{data.localServices.indexOf('%') > -1 ? '' : '%'}
						</Text>
						<Text style={styles.cellSubLabel}>
							which is Local Services
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View
						style={{
							...styles.cell,
							borderTop: 0,
							flex: 1,
							maxWidth: '25%',
						}}>
						<Text style={{ fontWeight: 'bold' }}>
							Inputs sourced Outside Lebanon
						</Text>
						<Text style={styles.cellSubLabel}>
							(Raw materials & Services)
						</Text>
					</View>
					<View
						style={{
							...styles.cell,
							borderTop: 0,
							flex: 3,
							maxWidth: '75%',
						}}>
						<Text style={{ textAlign: 'right' }}>
							{data.importsSourcedOutsideLeb}
							{data.importsSourcedOutsideLeb.indexOf('%') > -1
								? ''
								: '%'}
						</Text>
						<Text style={styles.cellSubLabel}>
							Imports used in production, International
							distribution or services from outside Lebanon,
						</Text>
					</View>
				</View>
				<View style={styles.cell}>
					<Text style={{ fontWeight: 'bold' }}>
						The above is to determine where the company in growth or
						through innovation can most impact on the local economy
						adding local economy expenditure and potentially
						creating local jobs directly & indirectly.
					</Text>
				</View>
				<View style={{ padding: 20, flex: 1 }} />
				<View style={styles.row}>
					<View style={styles.statsCellLarge}>
						<Text style={styles.cellLabel}>Company Data</Text>
					</View>
					<View style={styles.statsCell}>
						<Text style={styles.cellLabel}>2018</Text>
					</View>
					<View style={styles.statsCell}>
						<Text style={styles.cellLabel}>2019</Text>
					</View>
					<View style={styles.statsCell}>
						<Text style={styles.cellLabel}>2020</Text>
					</View>
					<View style={styles.statsCell}>
						<Text style={styles.cellLabel}>Projected 2021</Text>
					</View>
					<View style={styles.statsCell}>
						<Text style={styles.cellLabel}>Projected 2022</Text>
					</View>
					<View style={styles.statsCell}>
						<Text style={styles.cellLabel}>Projected 2023</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.statsCellLarge}>
						<Text style={styles.cellLabel}>Sales - $0,000s</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.sales2018}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.sales2019}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.sales2020}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.sales2021}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.sales2022}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.sales2023}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.statsCellLarge}>
						<Text style={styles.cellLabel}>
							% Sales - Increase / Decrease over previous year
						</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.salesFlux2018}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.salesFlux2019}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.salesFlux2020}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.salesFlux2021}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.salesFlux2022}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.salesFlux2023}</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.statsCellLarge}>
						<Text style={styles.cellLabel}>Export % of Sales</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.export2018}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.export2019}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.export2020}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.export2021}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.export2022}</Text>
					</View>
					<View style={styles.statsCell}>
						<Text>{data.export2023}</Text>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							Industry Addressed & Identified Opportunity:
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							{data.industryAddressed}
						</Text>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							New Product Developed/Introduced
							Description/Technology:
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							{data.productDescription}
						</Text>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							Competitive Edge/Value Proposition/USP:
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text
							style={styles.cellLabel}
							render={() => data.competitiveEdge}
						/>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							Business Model
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text
							style={styles.cellLabel}
							render={() => data.businessModel}
						/>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							Specific Target Markets
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							{data.targetMarket}
						</Text>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							Management Team
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>{data.team}</Text>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							Support/Investment Case
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>
							{data.investmentCase}
						</Text>
					</View>
				</View>
				<View style={{ ...styles.row, ...styles.headerRow }}>
					<View style={styles.cell}>
						<Text
							style={{
								...styles.cellLabel,
								color: 'white',
								fontSize: 18,
								fontWeight: 'bold',
							}}>
							Challenges and Impediments to Growth
						</Text>
					</View>
				</View>
				<View style={styles.row}>
					<View style={styles.cell}>
						<Text style={styles.cellLabel}>{data.challenges}</Text>
					</View>
				</View>
				<Text
					style={styles.pageNumber}
					render={({ pageNumber, totalPages }) =>
						`${pageNumber} / ${totalPages}`
					}
					fixed
				/>
			</Page>
		</Document>
	);
};

const styles = StyleSheet.create({
	body: {
		paddingTop: 35,
		paddingBottom: 65,
		paddingHorizontal: 35,
	},
	header: {
		fontSize: 11,
		color: '#2a2a86',
		textAlign: 'center',
	},
	title: {
		fontSize: 20,
		textAlign: 'center',
	},
	tableTitle: {
		fontSize: 18,
		color: 'white',
		fontWeight: 'bold',
	},
	row: {
		width: '100%',
		fontSize: 12,
		flex: 1,
		flexDirection: 'row',
		minWidth: 0,
	},
	headerRow: {
		backgroundColor: '#00cc00',
		color: 'white',
		borderBottom: 0,
		marginTop: 20,
	},
	cell: {
		border: 1,
		borderColor: '#00cc00',
		flex: 1,
		padding: 10,
		fontSize: 11,
		color: '#333',
		minWidth: '25%',
	},
	cellLabel: {
		color: '#333333',
		fontWeight: 'bold',
		flex: 1,
		minWidth: 0,
	},
	cellSubLabel: {
		color: '#333333',
		fontSize: 9,
		flex: 1,
		minWidth: 0,
	},
	statsCell: {
		border: 1,
		borderColor: '#00cc00',
		flex: 1,
		padding: 10,
		fontSize: 11,
		color: '#333',
		minWidth: 0,
	},
	statsCellLarge: {
		border: 1,
		borderColor: '#00cc00',
		flex: 3,
		padding: 10,
		fontSize: 11,
		color: '#333',
		width: '25%',
	},
	pageNumber: {
		position: 'absolute',
		fontSize: 12,
		bottom: 30,
		left: 0,
		right: 0,
		textAlign: 'center',
		color: 'grey',
	},
});
