import React, { useState, useEffect } from 'react';
// tslint:disable-next-line:no-var-requires
import {
	Datagrid,
	List,
	Show,
	Filter,
	SimpleShowLayout,
	TextField,
	TextInput,
	ShowButton,
	RichTextField,
	TopToolbar,
	Button,
} from 'react-admin';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { PdfDocument } from '../components/PdfDocument';
import { CircularProgress } from '@material-ui/core';

const SubmissionFilter = props => (
	<Filter {...props}>
		<TextInput
			label="Search"
			source="companyName"
			alwaysOn
			variant="filled"
		/>
	</Filter>
);

export const SubmissionList = props => (
	<List {...props} filters={<SubmissionFilter />}>
		<Datagrid>
			<TextField source="companyName" />
			<RichTextField source="email" />
			<ShowButton label="Show Details" />
		</Datagrid>
	</List>
);

export const SubmissionShow = props => {
	const PostShowActions = ({ basePath, data, resource }) => {
		const [shouldShowPDF, setShouldShowPDF] = useState(false);
		const [isLoading, setIsLoading] = useState(false);
		console.log(data);
		console.log(basePath);
		console.log(resource);
		console.log(props);

		useEffect(() => {
			let timeOut = () => {};
			if (shouldShowPDF) {
				setIsLoading(true);
				timeOut = setTimeout(() => setIsLoading(false), 1500);
			}
			return () => timeOut();
		}, [shouldShowPDF]);

		return (
			<TopToolbar>
				{data && data.email && !shouldShowPDF && (
					<Button
						label="Generate PDF for export"
						onClick={() => setShouldShowPDF(data && data.email)}
					/>
				)}
				{isLoading && <CircularProgress />}
				{!isLoading && shouldShowPDF && (
					<PDFDownloadLink
						document={<PdfDocument data={data} />}
						fileName={`EU-TAF-submissions-${new Date().toLocaleString()}.pdf`}>
						{({ blob, url, loading, error }) =>
							loading ? 'Loading document...' : 'Download Pdf'
						}
					</PDFDownloadLink>
				)}
			</TopToolbar>
		);
	};

	return (
		<Show {...props} actions={<PostShowActions />}>
			<SimpleShowLayout>
				<TextField source="id" />
				<hr />
				<h2>General</h2>
				<TextField source="companyName" label="Company Name" />
				<TextField source="industrySector" label="Industry Sector" />
				<TextField source="headquarterAddress" label="HQ Address" />
				<TextField
					source="companyRegNumber"
					label="Company Registration Number"
				/>
				<TextField source="contactName" label="Contact Name" />
				<TextField source="title" label="Title" />
				<TextField source="phone" label="Phone Number" />
				<TextField source="email" label="Email Address" />
				<TextField source="website" label="Website" />
				<TextField source="socialMedia" label="Social Media" />
				<TextField source="industrySegment" label="Industry Segment" />
				<TextField source="dateEstablished" label="Date Established" />
				<TextField
					source="numberOfEmployees"
					label="Number Of Employees"
				/>
				<TextField source="customerFocus" label="Customer Focus" />
				<TextField source="customerType" label="Customer Type" />
				<TextField source="operationType" label="Operation Type" />
				<TextField source="mainProducts" label="Main Products" />
				<TextField source="businessType" label="Business Model" />
				<TextField source="legalForm" label="Legal Form" />
				<TextField
					source="sharesManagement"
					label="Management Shares"
				/>
				<TextField source="sharesInvestors" label="Investor Shares" />
				<TextField source="sharesPartners" label="Partner Shares" />
				<TextField source="sharesESOP" label="ESOP Shares" />
				<TextField
					source="inHouseAddedValue"
					label="In House Added Value"
				/>
				<TextField
					source="localRawMaterials"
					label="Local Raw Materials"
				/>
				<TextField source="localServices" label="Local Services" />
				<TextField
					source="importsSourcedOutsideLeb"
					label="Imports Sourced Outside Lebanon"
				/>
				<hr />
				<h2>Financials</h2>
				<TextField source="sales2018" label="Sales 2018" />
				<TextField source="salesFlux2018" label="Sales Flux 2018" />
				<TextField source="export2018" label="Exports 2018" />
				<TextField source="sales2019" label="Sales 2019" />
				<TextField source="salesFlux2019" label="Sales Flux 2019" />
				<TextField source="export2019" label="Exports 2019" />
				<TextField source="sales2020" label="Sales 2020" />
				<TextField source="salesFlux2020" label="Sales Flux 2020" />
				<TextField source="export2020" label="Exports 2020" />
				<TextField source="sales2021" label="Sales 2021" />
				<TextField source="salesFlux2021" label="Sales Flux 2021" />
				<TextField source="export2021" label="Exports 2021" />
				<TextField source="sales2022" label="Sales 2022" />
				<TextField source="salesFlux2022" label="Sales Flux 2022" />
				<TextField source="export2022" label="Exports 2022" />
				<TextField source="sales2023" label="Sales 2023" />
				<TextField source="salesFlux2023" label="Sales Flux 2023" />
				<TextField source="export2023" label="Exports 2023" />
				<hr />
				<h2>Business Plan</h2>
				<TextField
					source="industryAddressed"
					label="Industry Addressed"
				/>
				<TextField
					source="productDescription"
					label="Product Description"
				/>
				<TextField source="competitiveEdge" label="Competitive Edge" />
				<TextField source="businessModel" label="Business Model" />
				<TextField source="targetMarket" label="Target Market" />
				<TextField source="team" label="Team" />
				<TextField source="investmentCase" label="Investment Case" />
				<TextField source="challenges" label="Callenges" />
			</SimpleShowLayout>
		</Show>
	);
};

// export const SubmissionCreate = props => (
// 	<Create {...props}>
// 		<SimpleForm>
// 			<TextInput source="title" />
// 			<RichTextInput source="body" />
// 			<ReferenceInput label="Comment" source="title" reference="comments">
// 				<SelectInput optionText="title" />
// 			</ReferenceInput>
// 			<FileInput source="file" label="File" accept="application/pdf">
// 				<FileField source="src" title="title" />
// 			</FileInput>
// 		</SimpleForm>
// 	</Create>
// );

// export const SubmissionEdit = props => (
// 	<Edit {...props}>
// 		<SimpleForm>
// 			<ReferenceInput source="id" options={{ disabled: true }} />
// 			<ReferenceInput source="createdate" options={{ disabled: true }} />
// 			<ReferenceInput source="lastupdate" options={{ disabled: true }} />
// 			<ReferenceInput label="Comment" source="title" reference="comments">
// 				<SelectInput optionText="title" />
// 			</ReferenceInput>
// 			<TextInput source="title" />
// 			<RichTextInput source="body" />
// 			<SelectInput
// 				source="rating"
// 				choices={[
// 					{ id: 1, name: 'Good' },
// 					{ id: 2, name: 'Okay' },
// 					{ id: 3, name: 'Bad' },
// 				]}
// 			/>
// 			<FileInput source="file" label="File" accept="application/pdf">
// 				<FileField source="src" title="title" />
// 			</FileInput>
// 		</SimpleForm>
// 	</Edit>
// );
