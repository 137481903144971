// "site": "eu-taf-admin",
// firebase deploy --only hosting:eu-taf-admin

export const firebaseConfig = {
	apiKey: 'AIzaSyAcgToS7V-0Ve2207rEYtJXhCz_n4YN0nY',
	authDomain: 'eu-taf.firebaseapp.com',
	databaseURL: 'https://eu-taf.firebaseio.com',
	projectId: 'eu-taf',
	storageBucket: 'eu-taf.appspot.com',
	messagingSenderId: '542813516706',
	appId: '1:542813516706:web:1c6ce39dfef657fc5fb8fb',
};
